<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button type="primary" v-access="{url:baseUrl,method:'POST'}" @click="addCode" size="medium" slot="add" icon="el-icon-plus">新建示例</el-button>
            </search>
        </div>
        <div class="mt-3" id="table-area">
            <my-table :sort-change="sortChange" :sort="page.sort" :fields="showFields" :data="tableData">
                <el-table-column
                    slot="operation"
                    width="130"
                    label="操作"
                >
                    <template slot-scope="scope">
                        <el-button
                            class="rank"
                            size="mini"
                            :data-id="scope.row.id"
                            circle
                            type="default"
                            icon="el-icon-rank"
                        >
                        </el-button>
                        <el-button
                            class="rank"
                            size="mini"
                            :data-id="scope.row.id"
                            circle
                            type="default"
                            icon="el-icon-view"
                            @click="seeDetail(scope.row)"
                        >
                        </el-button>
                        <el-button
                            size="mini"
                            circle
                            type="primary"
                            icon="el-icon-edit"
                            @click="editCode(scope.$index)">
                        </el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog ref="formDialog" @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
        <el-dialog
            title="当前进度"
            :visible.sync="process.show"
            width="30%">
            <span v-html="process.text"></span>
            <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import Sortable from 'sortablejs';

export default {
    name: "groupSending",
    components: {
        search
    },
    data: function () {
        return {
            baseUrl:'/big/offline/index',
            process:{
                show:false,
                title:'当前进度',
                text:'',
                index:null,
                messageId:null
            },
            page: {
                search: {},
                sort: {prop: 'create_time', order: 'descending'},
                // sort: {},
                total: 1,
                pageSize: 10,
                pageNumber: 1,
            },
            tableData: [

            ],
            actionName: '',
            index:null,
            message_id:null,
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    beforeDestroy() {
        this.message_id=null;
    },
    mounted() {
        let dict=['offline_type'];
        dict._url=process.env.VUE_APP_COM_HOST+'/big/api/dict';
        this.$store.dispatch('getDict',dict);
        let dom = document.getElementById('table-area').getElementsByTagName('tbody')[0];
        new Sortable(dom, {
            handle: '.rank',
            onEnd: () => {
                let data = {type: 'order', data: {}};
                [...dom.getElementsByClassName('rank')].map((v, index) => {
                    data.data[v.dataset.id] = index;
                });
                this.$com.put(this.baseUrl, data).then(() => {
                    this.$message.success('排序成功!');
                });
            }
        });
    },
    computed: {
        searchFields: function () {
            return F.search('offlineList2d', 'search');
        },
        showFields: function () {
            return F.show('offlineList2d', 'show');
        }
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$com.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editCode':
                    this.$com.put(this.baseUrl, data.data).then(userData => {
                        this.form.show = false;
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.form.index, 1, userData.data);
                    });
                    break;
                case 'addCode':
                    this.$com.post(this.baseUrl, data.data).then((data) => {
                        this.$message.success('添加成功!');
                        this.form.show = false;
                        this.tableData.unshift(data.data);
                    });
                    break;
            }
        },
        editCode: function (index) {
            this.actionName = 'editCode';
            let form = F.get('offlineList2d', 'edit',this.tableData[index]);
            form.index=index;
            this.form = form;
        },
        addCode:function(){
            this.actionName = 'addCode';
            // let form = F.get('groupSending', 'add',{thumbnail:[{url:process.env.VUE_APP_MAIL_HOST+'/uploads/20200614/ac11379591348ac3df3513138199b34f.png'}]});
            // let ii=[];
            // for (let i=0;i<8;i++){
            //     ii.push({url:'http://www.bigemap.com/upload/columnthumbnail/20180328/5abb657fa458f.png'});
            // }
            let form = F.get('offlineList2d', 'add',{status:1});
            form.show = true;
            this.form = form;
        },
        seeDetail(detail){
            let a=document.createElement('a');
            a.href=`http://www.bigemap.com/bigemap_index.php/offlinemaps/demo/${detail.name}.html?t=`+Math.random();
            a.target='_blank';
            a.click();
        },
        cancel:function(){
            this.$confirm('你确认要取消吗？','操作确认').then(()=>{
                this.message_id='';
                this.process.show=false;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
    }
}
</script>
<style scoped>

</style>
